export const sysId = `
  __typename 
  sys { 
    id
  }
`;

export const whereSysPubExists = `where: { sys:{ publishedAt_exists: true } }`;

export const assetFields = `
  ${sysId}
  title
  url
  width
  height
  description
`;

export const pageTagCollectionFields = `
  pageTagCollection(
    limit: 2
    ${whereSysPubExists}
  ) {
    items {
      ${sysId}
      tagName
      tagType
    }
  }
`;

export const contentfulRichTextFields = `
json
links {
  assets {
    __typename
  }
  entries {
    block {
      ${pageTagCollectionFields} 
    }
    inline {
      __typename
      ${pageTagCollectionFields} 
    }
    hyperlink {
      ${pageTagCollectionFields} 
    }
  }
}
`;

export const dataNavItemFields = `
  ${sysId}
  navText,
  navUrl,
  navDescription
  navOverarchCtaTextOverride
`;

export const dataDigitalAsset = `
  ${sysId}
  assetTitle
  assetSourceLocal(locale: "en") {${assetFields}}
  assetSourceRemote
  assetDescription
  videoLength
  videoThumbnail {${assetFields}}
  pillarColour
  enableLightbox
  previewVideo(locale: "en") {${assetFields}}
  playWithoutSound
  gatedMediaCheckbox
  gatedMediaMarketoFormId
`;

export const dataDigitalDownload = `
  ${sysId}
  url
  size
  fileName
  contentType
`;

export const dataFeaturedLinkFields = `
  ${sysId}
  featuredTitle
  featuredHeading
  featuredDescription
  featuredImage(locale: "en") {${assetFields}}
  featuredUrl
  featuredPage {
    ... on PageStandardPage {
      slug
    }
  }
  featuredShowButton
  featuredButtonText
`;

export const navigationItemFieldsTwo = `
  ${sysId}
  navTitle
  navText
  navUrl
  navDescription
  navOverarchCta(${whereSysPubExists}) {
    pageTitle
    pageHeading
    slug
  }
  navOverarchCtaTextOverride
  navChildrenCollection(
    limit: 20
    ${whereSysPubExists}
  ) {
    items {
      ... on DataNavItem {${dataNavItemFields}}
    }
  }
`;

export const navigationItemFieldsOne = `
  ${sysId}
  navTitle
  navText
  navUrl
  navDescription
  navOverarchCta {
    pageTitle
    pageHeading
    navigationTag {
        tagText
        tagTitle
        tagBgHex
        tagFgHex
    }
    slug
  }
  navOverarchCtaTextOverride
  navFeaturedLink {
    ${dataFeaturedLinkFields}
  }
  navChildrenCollection(
    limit: 3
    ${whereSysPubExists}
  ) {
    items {
      ... on DataNavItem {${navigationItemFieldsTwo}}
    }
  }
`;

export const navigationTagFields = `
  ${sysId}
  tagText
  tagTitle
  tagBgHex
  tagFgHex
`;

export const ctaButtonFields = `
  ${sysId}
  ctaTitle
  ctaText
  ctaAria
  ctaStyle
  ctaIcon
  ctaIconPosition
  ctaUrl
  ctaPage {
    pageTitle
    pageHeading
    slug
  }
  ctaDigitalAsset {
    ${dataDigitalDownload}
  }
  ctaMarketoFormId
`;

const dataPillarContentFields = `
  ${sysId}
  pillarText
  pillarColour
`;

export const insightTypeReferenceFields = `
  insightTypeReference(${whereSysPubExists}) {
    ${sysId}
    insightName
    pluralName
    showDateInCards
  }
`;

export const dataEmployeeFields = `
  ${sysId}
  employeeBio
  employeeName
  employeeTitle
  employeeImage(locale: "en") {${assetFields}}
  employeeJobTitle
  employeeTagCollection(
    limit:2
    ${whereSysPubExists}
  ) {
    items {
      ${sysId}
      tagText
      tagTitle
      tagBgHex
      tagFgHex
    }
  }
`;

export const pagePreviewFields = `
  ${sysId}
  slug
  parentPage {
    ... on PageStandardPage {
      slug
    }
    ... on PageInsightsListing {
      slug
    }
  }
  ${insightTypeReferenceFields}  
  pageHeading
  previewText
  previewMedia(locale: "en") {
    ${assetFields}
  }
  navigationTag {
    ${navigationTagFields}
  }
  ${pageTagCollectionFields}
`;

export const componentIntroductionFields = `
  ${sysId}
  textBlockTitle
  textBoxBody
  cta {
    ${ctaButtonFields}
  }
  pillarContentCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
      ... on DataPillarContent {${dataPillarContentFields}}
      ... on DataEmployee {${dataEmployeeFields}}
      ... on PageStandardPage {${pagePreviewFields}}
    }
  }
`;

export const dataArticleAnchorFields = `
  ${sysId}
  anchorPointId
  anchorLinkTitle
`;

export const componentHeroFields = `
  ${sysId}
  heroStyle
  heroColour
  showBreadcrumb
  heroSubheading
  heroHeading
  heroBody
  heroMedia(locale: "en") {
    contentType
    ${assetFields}
  }
  heroSpacing
  ctasCollection(
    limit:2
    ${whereSysPubExists}
  ){
    total
    items {
      ... on ComponentCta {
       ${ctaButtonFields}
      }
    }
  }
  previewVideo(locale: "en") {${assetFields}}
  videoTitle
  playWithoutSound
  gatedMediaCheckbox
  gatedMediaMarketoFormId
  allowMiniPlayer
`;

export const componentMarketoBlockFields = `
  ${sysId}
  marketoType
  marketoHeading
  marketoBody
  marketoCtaText
  marketoEmailText
  marketoEmailExampleText
  marketoTopics
  marketoTopicPromptText
  marketoTopicText
  marketoFormId
  marketoSuccessMessage
`;

export const componentMultiCardCalloutFields = `
  ${sysId}
  calloutHeading
  insightsMultiCardCallout
  cardsCollection(
    limit:8
    ${whereSysPubExists}
  ) {
    total
    items {
      ${pagePreviewFields}
    }
  }
  ctaText
`;

export const dataImageHeadingBodyFields = `
  ${sysId}
  ihbHeading
  ihbIcon {   
    contentType
    ${assetFields}
  }
  ihbHeadline
  ihbBody
  ihbImage(locale: "en")  {
    ${assetFields}
  }
  enableLightbox
  ihbImageCaption
  ihbCta {
    ${ctaButtonFields}
  }
  pillarColour
`;

export const componentFeaturedItemsFields = `
  ${sysId}
  informationListingTitle
  informationListingStyle
  informationListingKeyline
  informationListingHeading
  informationListingRichText
  informationListingItemsCollection(
    limit: 16
    ${whereSysPubExists}
  ) {
    items {
      ${dataImageHeadingBodyFields}
    }
  }
  informationListingCalloutCta {
    ${ctaButtonFields}
  }
  relatedInsight {
    ... on PageStandardPage {${pagePreviewFields}}
    ... on DataFeaturedLink {${dataFeaturedLinkFields}}
  }
`;
export const componentFeaturedItemsFieldsTabVersion = `
  ${sysId}
  informationListingTitle
  informationListingStyle
  informationListingKeyline
  informationListingHeading
  informationListingRichText
  informationListingItemsCollection(
    limit: 8
    ${whereSysPubExists}
  ) {
    items {
      ${dataImageHeadingBodyFields}
    }
  }
  informationListingCalloutCta {
    ${ctaButtonFields}
  }
  relatedInsight {
    ... on PageStandardPage {${pagePreviewFields}}
    ... on DataFeaturedLink {${dataFeaturedLinkFields}}
  }
`;

export const componentFeaturedItemsAccordionVersion = `
  ${sysId}
  informationListingTitle
  informationListingStyle
  informationListingKeyline
  informationListingHeading
  informationListingRichText
  informationListingItemsCollection(
    limit:4
    ${whereSysPubExists}
  ) {
    items {
      ${dataImageHeadingBodyFields}
    }
  }
  informationListingCalloutCta {
    ${ctaButtonFields}
  }
`;

const dataCareerVacancyFields = `
  ${sysId}
  vacancyHeading
  vacancyBody
  vacancyTag
  vacancyLink
`;

export const componentCardListingFields = `
  ${sysId}
  clHeading
  clIntro
  ctaButton {
    ${ctaButtonFields}
  }
  secondaryCtaText
  secondaryCtaLink
  cardCtaText
  cardListingCollection(
    limit:16
    ${whereSysPubExists}
  ) {
    items {
      ... on PageStandardPage {${pagePreviewFields}}
      ... on DataCareerVacancy {${dataCareerVacancyFields}}
    }
  }
`;

const richTextFields = `
  ${sysId}
  bodyTitle
  bodyContent
`;

export const componentRichTextBodyFields = `
  ${sysId}
  relatedInsightHeader
  relatedInsight {
    ... on PageStandardPage {${pagePreviewFields}}
    ... on DataFeaturedLink {${dataFeaturedLinkFields}}
  }
  richTextTitle
  richTextBodyComponentsCollection(
    limit: 6
    ${whereSysPubExists}
  ) {
    items {
      __typename
      ... on DataRichText {
        ${richTextFields}
      }
      ... on DataVideo {
        ${dataDigitalAsset}
      }
      ... on ComponentCta {
        ${ctaButtonFields}
      }
    }
  }
`;

export const componentFiftyFiftyCalloutFields = `
  ${sysId}
  calloutTitle
  calloutStyle
  calloutHeading
  calloutBody
  textAlignment
  calloutCta {
    ${ctaButtonFields}
  }
  calloutImage(locale: "en") {    
    contentType
    ${assetFields}
  }
  calloutCaption
  pillarColour
  previewVideo(locale: "en") {${assetFields}}
  playWithoutSound
  gatedMediaCheckbox
  gatedMediaMarketoFormId
`;

export const componentTestimonialCarouselFields = `
  ${sysId}
  carouselHeading
  slideXLabel
  slideXCountLabel
  nextLabel
  previousLabel
  testimonialsCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
      testimonialQuote
      testimonialName
      testimonialNameSub
      testimonialImage {
        ${assetFields}
      }
    }
  }
`;

export const componentBannerCarouselFields = `
  carouselHeading
  slidesCollection(
    limit:4
    ${whereSysPubExists}
  ) {
    items {
      ...on ComponentHero {${componentHeroFields}}
    }
  }
`;

export const componentHeroCarouselFields = `
  ${sysId}
  carouselHeading
  slidesCollection(
    limit:4
    ${whereSysPubExists}
  ) {
    items {
      ...on ComponentHero {${componentHeroFields}}
    }
  }
  slideDelay
`;

export const componentMediaGalleryFields = `
  ${sysId}
  galleryTitle
  galleryAssetsCollection(
    limit: 2
    ${whereSysPubExists}
  ) {
    items {
      ${dataDigitalAsset}
    }
  }
`;

export const componentLatestInsightsCallout = `
  ${sysId}
  insightsTitle
  insightsHeading
  seeAllText
  seeAllLink
  showDateInLargeCard
  largeCard(${whereSysPubExists}) {
    ${sysId}
    previewMedia(locale: "en") {
      ${assetFields}
    }
    ${insightTypeReferenceFields}    
    publishDate
    pageHeading
    slug
    ${pageTagCollectionFields}
  }
  smallCardsCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
      ${sysId}
      ${insightTypeReferenceFields}      
      publishDate
      pageHeading
      slug
      ${pageTagCollectionFields}
    }
  }
  ctaText
  dynamicLatestInsights
`;


export const articleSections = `
  ${sysId}
  articleTitle
  sectionBodyComponentsCollection(
    limit: 6
    ${whereSysPubExists}
  ){
    items {
      __typename
      ... on DataRichText {
        ${richTextFields}
      }
      ... on DataVideo {
        ${sysId}
        ${dataDigitalAsset}
      }
      ... on ComponentCta {
        ${sysId}
        ${ctaButtonFields}
      }
    }
  }
  showInArticleNavigation
`;

export const componentArticleContent = `
  articleTitle
  articleSectionsCollection(
    limit: 6
    ${whereSysPubExists}
  ) {
    items {
      ${articleSections}
    }
  }
  relatedInsights {
    ... on PageStandardPage {
      ${sysId}
      pageTitle
      pageHeading
      slug
      ${insightTypeReferenceFields}
      previewText
      previewMedia(locale: "en") {
        ${assetFields}
      }
      navigationTag {
        ${sysId}
        tagTitle
        tagText
      }
      ${pageTagCollectionFields}
    }
    ... on DataFeaturedLink {${dataFeaturedLinkFields}}
  }
  inThisArticleText
  shareText
  showInThisArticle
  dataDrivenInsightText
  showShare
  showArticleAuthorFootnote
`;

export const componentEmployeeBlockFields = `
  ${sysId}
  employeeBlockHeading
  employeeCardsCollection(
    limit: 9
    ${whereSysPubExists}
  ) {
    items {
      ${sysId}
      employeeName
      employeeJobTitle
      employeeBio
      employeeLinkedIn
      employeeTwitter
      employeeProfileImage(locale: "en")  {
        ${assetFields}
      }
      employeeImage(locale: "en")  {
        ${assetFields}
      }
    }
  }
`;

export const standardPageComponentTypeNames = `
  componentsCollection(
    limit:20
  ) {    
    items {
      __typename
    }
  }
`;

export const standardPageComponentCount = `
  componentCount: componentsCollection(
    limit:20
  ) {
    total    
  }
`;

export const searchPageComponentCollectionFields = `
  pageTopComponentsCollection(
    limit: 2
    ${whereSysPubExists}
  ) {
    items {
      __typename
      ...on ComponentIntroduction {${componentIntroductionFields}}
      ...on ComponentHero {${componentHeroFields}}
    }
  }
  pageBottomComponentsCollection(
    limit: 2
    ${whereSysPubExists}
  ) {
    items {
      __typename
      ...on ComponentMarketoBlock {${componentMarketoBlockFields}}
      ...on ComponentMultiCardCallout {${componentMultiCardCalloutFields}}
    }
  }
`;

export const insightsPageComponentCollectionFields = `
  pageTopCuratedAreaCollection(limit: 1) {
    items {
      __typename
      ...on ComponentHero {${componentHeroFields}}
    }
  }
  pageBottomCuratedAreaCollection(limit: 3) {
    items {
      __typename
      ...on ComponentHero {${componentHeroFields}}
      ...on ComponentMultiCardCallout {${componentMultiCardCalloutFields}}
      ...on ComponentMarketoBlock {${componentMarketoBlockFields}}
    }
  }
  insightsComponentsCollection(limit: 2) {
    items {
      __typename
      ...on ComponentHero {${componentHeroFields}}
      ...on ComponentMultiCardCallout {${componentMultiCardCalloutFields}}
    }
  }
`;

export const componentAccordionFields = `
  __typename
  ${sysId}
  accordionHeading
  accordionItemsCollection(
    limit: 10
    ${whereSysPubExists}
  ) {
    total
    items {
      ...on ComponentFeaturesItems {
        __typename
        ${componentFeaturedItemsAccordionVersion} 
      }
      ...on ComponentFiftyFiftyCallout {
        __typename
        ${componentFiftyFiftyCalloutFields} 
      }
    }
  }
`;

export const componentAccordionFieldsInsideTab = `
  __typename
  ${sysId}
  accordionHeading
  accordionItemsCollection(
    limit: 8
    ${whereSysPubExists}
  ) {
    total
    items {
      ...on ComponentFeaturesItems {
        __typename
        ${componentFeaturedItemsAccordionVersion} 
      }
      ...on ComponentFiftyFiftyCallout {
        __typename
        ${componentFiftyFiftyCalloutFields} 
      }
    }
  }
`;

export const componentContentTabFields = `
  ${sysId}
  heading
  subheading
  contentAreaCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
      ...on ComponentFiftyFiftyCallout {
        __typename
        ${componentFiftyFiftyCalloutFields} 
      }
      ... on ComponentRichText {
        __typename
        ${componentRichTextBodyFields}
      }
      ...on ComponentFeaturesItems {
        __typename
        ${componentFeaturedItemsFieldsTabVersion}
      }
      ... on ComponentAccordion {
        __typename
        ${componentAccordionFieldsInsideTab}
      }
      ... on ComponentTestimonialCarousel {
        __typename
        ${componentTestimonialCarouselFields}
      }
    }
  }
`;

export const componentFeatureTabsFields = `
  ${sysId}
  tabbedTitle
  tabbedHeading
  tabbedStyle
  presentationType
  displayMode
  tabbedContentCollection(
    limit: 4
    ${whereSysPubExists}
  ) {
    items {
      __typename 
      ... on DataImageHeadingBody {${dataImageHeadingBodyFields}}
      ... on ComponentContentTab {${componentContentTabFields}}
    }
  }
  splitTabbedContentText
`;