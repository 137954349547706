import { GetStaticProps } from 'next';

import {
  runGraphQLQuery,
  getBaseStaticProps,
  getInsightsPerPageData,
  getNewsletterProps,
  getLatestInsightsCards,
} from './api';

import { compressQuery } from '../utils';

import {
  pageQuery,
  searchQuery,
  insightsQuery,
  GetPageQueryProps,
} from './queries';

import { convertCollectionItems } from './builder';
import { getStandardPageStaticPaths } from './getStaticPaths';
import { getUniqueData, componentFieldsMapped } from '@/ui';
import { InsightTypeRef } from '@/types';

const getStaticPropsBySlug = async (
  context: any,
  queryFn: Function,
  collectionKey: string
) => {
  const { params, locale, preview = false } = context;

  const slug = params?.slug[params.slug.length - 1];
  const pageQuery = queryFn({ slug, locale, preview });

  let data: any;
  let mergedData: any[] = [];

  if (Array.isArray(pageQuery)) {

    for (const query of pageQuery) {
      const Innerdata = await runGraphQLQuery(compressQuery(query), locale, preview);
      if (Innerdata) {
        mergedData.push(Innerdata);
      }
    }

    data = {
      [collectionKey]: [
        {
          components: {
            items: [
              mergedData.map((item, index) => (
                item[collectionKey][0]?.components.items[index]
              ))
            ]
          }
        }
      ]
    };

    return data;
  } else {
    data = await runGraphQLQuery(pageQuery, locale, preview);
  }

  const baseData = await getBaseStaticProps(locale, preview);
  const allPaths = await getStandardPageStaticPaths(context);
  // Makes sure that the pull path is a match otherwise it will resolve with Next catch all segments.
  const validPath = allPaths?.paths.some(
    (item) =>
      // @ts-expect-error
      JSON.stringify(item?.params?.slug) === JSON.stringify(params?.slug)
  );

  return {
    props: {
      isPreviewMode: preview ?? false,
      pageData: data?.[collectionKey][0] || {},
      baseData: baseData?.['configurationSite'][0] || {},
      paths: allPaths?.paths || [],
      validPath,
    },
    revalidate: 60,
  };
};

export const getStandardPageStaticProps: GetStaticProps = async (context) => {
  const { locale } = context as any;

  // Fetch the standard page data using getStaticPropsBySlug
  const standardPageProps = await getStaticPropsBySlug(
    context,
    pageQuery,
    'pageStandardPage'
  );

  if (
    !standardPageProps?.props?.pageData ||
    Object.keys(standardPageProps.props.pageData).length === 0 ||
    !standardPageProps?.props?.validPath
  ) {
    return {
      redirect: {
        destination: `/${locale ?? 'en'}/404`,
        permanent: false,
      },
    };
  }

  let preview = standardPageProps?.props?.isPreviewMode;

  let components: string[] = [];
  // Get the component typenames on the page, for constructing the component queries
  const getComponentTypeNames = standardPageProps?.props?.pageData.components;
  const getComponentTypeCount =
    standardPageProps?.props?.pageData.componentCount.total;

  const finalQueryFn = ({ slug, locale, preview }: { slug: string; locale: string; preview: boolean }) => {
    let arrayQuery: string[] = [];

    getComponentTypeNames?.forEach((item: { __typename: any }) => {
      const typename = item.__typename;
      const fields = componentFieldsMapped(typename);
      if (fields) {
        const componentQueryFn = ({
          slug,
          locale,
          preview,
          limit = 1,
        }: GetPageQueryProps) => {
          const slugFilter = slug ? `where: {slug: "${slug}"}` : '';
          let previewTrue = preview ? 'preview: true' : '';
          return ` query {
          pageStandardPageCollection(
            ${slugFilter}
            limit: ${limit}
            locale: "${locale}"
            ${previewTrue} 
          ) {
            items {
              components: componentsCollection(limit:${getComponentTypeCount}) {
                items {
                  __typename
                  ... on ${typename} {${fields}}
                }
              }
            }
          }
        }`;
        };
        arrayQuery.push(componentQueryFn({ slug, locale, preview }));

      }
    });
    return arrayQuery;
  };

  // Fetch the query using the getStaticPropsBySlug, and extract the components array
  const query: Record<string, any> = await getStaticPropsBySlug(
    context,
    finalQueryFn,
    'pageStandardPage'
  );

  // Merge the recieved components into the components array
  components = query['pageStandardPage'][0].components.items[0];

  // Filter out empty components
  const nonEmptyComponents = components.filter((component) => {
    return component && Object.keys(component).length > 1;
  });

  // Convert the components to the correct format for rendering
  components = convertCollectionItems(nonEmptyComponents);

  // Merge standard pageData with components Array, for full page content
  const mergedPageData: Record<string, any> = {
    ...standardPageProps?.props?.pageData,
    components: components,
  };

  // Check to see if Marketo Data Caputre id is set
  let newsletterBlock = {};
  const entryId =
    standardPageProps?.props?.baseData?.globalNewsletterComponentId;
  if (entryId) {
    newsletterBlock = await getNewsletterProps(entryId, locale, false);
  }

  const latestInsightsCards = await getLatestInsightsCards(locale, preview);

  return {
    props: {
      isPreviewMode: preview,
      pageData: mergedPageData,
      baseData: standardPageProps?.props?.baseData,
      paths: standardPageProps?.props?.paths,
      validPath: standardPageProps?.props?.validPath,
      newsletterBlock: newsletterBlock || {},
      latestInsightsCards: latestInsightsCards,
    },
    revalidate: 60,
  };
};

export const getSearchPageStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;

  const searchPageProps = await getStaticPropsListing(
    context,
    searchQuery,
    'pageSearch'
  );

  if (
    !searchPageProps?.props?.pageData ||
    Object.keys(searchPageProps.props.pageData).length === 0
  ) {
    return {
      redirect: {
        destination: `/${locale ?? 'en'}/404`,
        permanent: false,
      },
    };
  }
  return searchPageProps;
};

const getStaticPropsListing = async (
  context: any,
  queryFn: Function,
  collectionKey: string
) => {
  const { params, locale, preview = false } = context;
  const listingQuery = queryFn({ locale, preview });
  const data = await runGraphQLQuery(listingQuery, locale, preview);
  const baseData = await getBaseStaticProps(locale, preview);

  // TODO - update static paths to get all page types.
  const allPaths = await getStandardPageStaticPaths(context);

  const getTotalPosts = data?.allStandardPages?.total;

  const insightPerPage = await getInsightsPerPageData(locale, preview);

  const pageTags = getUniqueData(
    data?.pageTags?.items
      .flatMap(
        (item: { pageTagCollection: { items: string[] } }) =>
          item?.pageTagCollection?.items
      )
      .sort((a: any, b: any) => a.tagName.localeCompare(b.tagName))
  );

  const insightTypeRef = data?.insightTypes?.items;
  let insightRef: string[] = [];
  if (insightTypeRef) {
    const validItems = insightTypeRef.filter(
      (item: { insightTypeReference: null }) =>
        item.insightTypeReference !== null
    );
    validItems.forEach((item: InsightTypeRef) => {
      if (item.insightTypeReference?.pluralName) {
        insightRef.push(item.insightTypeReference.pluralName);
      }
    });
  }

  const sortedInsightRef = insightRef.sort((a, b) => a.localeCompare(b));
  const uniqueInsightRef = getUniqueData(sortedInsightRef);

  const insightReferences = uniqueInsightRef;

  return {
    props: {
      baseData: baseData?.['configurationSite'][0] || {},
      pageData: data?.[collectionKey][0] || {},
      listingItems: data?.['pageStandardPage'] || [],
      isPreviewMode: preview ?? false,
      currentPage: params?.page || 1,
      totalPages: Math.ceil(getTotalPosts / insightPerPage),
      insightPerPage: insightPerPage,
      insightTypes: insightReferences,
      pageTags: pageTags,
      paths: allPaths?.paths || [],
    },
    revalidate: 60,
  };
};

export const getInsightsListingStaticProps: GetStaticProps = async (
  context
) => {
  const { locale } = context as any;

  const insightsListingProps = await getStaticPropsListing(
    context,
    insightsQuery,
    'pageInsightsListing'
  );

  if (
    !insightsListingProps?.props?.pageData ||
    Object.keys(insightsListingProps.props.pageData).length === 0
  ) {
    return {
      redirect: {
        destination: `/${locale ?? 'en'}/404`,
        permanent: false,
      },
    };
  }

  return insightsListingProps;
};
