import ReactPlayer from 'react-player/lazy';
import styles from './FeaturedVideo.module.scss';
import { useEffect, useState } from 'react';
import { Icon } from '@/ui/base';

const FeaturedVideo = ({
  previewMedia,
  featuredMedia,
  isMuted,
  isPlaying = false,
  isPlayingPreview = true,
  isPlayingMain = false,
  expanded = false,
  miniPlayer = false,
  onEnded = () => {},
}: {
  previewMedia?: any;
  featuredMedia?: any;
  isMuted?: boolean;
  isPlaying?: boolean;
  isPlayingPreview?: boolean;
  isPlayingMain?: boolean;
  expanded?: boolean;
  miniPlayer?: boolean;
  onEnded?: () => void;
}) => {
  const pictureInPictureOnScroll = miniPlayer || false;

  const [isMiniPlayerPlaying, setIsMiniPlayerPlaying] = useState(isPlaying);
  const [isMiniPlayer, setIsMiniPlayer] = useState(false);
  const [isPlayingState, setIsPlayingState] = useState(isPlaying);

  const togglePlayPause = () => {
    const newPlayingState = !isMiniPlayerPlaying;
    setIsMiniPlayerPlaying(newPlayingState);
    setIsPlayingState(newPlayingState);
  };

  const setFullScreen = () => {
    const video = document.querySelector('.allowPIPVideo');
    if (video) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        video.requestFullscreen();
      }
    }
  };

  const closeMiniPlayer = () => {
    setIsMiniPlayer(false);
    setIsMiniPlayerPlaying(false);
    setIsPlayingState(false);
    onEnded();
    const videoWrapper = document.querySelector('.allowPIPWrapper');
    const heroWrapper = document.querySelector('.featuredVideoHero');
    if (videoWrapper && heroWrapper) {
      videoWrapper.classList.remove('fixed');
      heroWrapper.classList.remove('fixed');
    }
  };

  useEffect(() => {
    if (pictureInPictureOnScroll) {
      const videoRef = document.querySelector('.allowPIPWrapper');
      const video = document.querySelector('.allowPIPWrapper');
      const heroWrapper = document.querySelector('.featuredVideoHero');
      if (videoRef && video && heroWrapper) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.remove('fixed');
                heroWrapper.classList.remove('fixed');
                setIsMiniPlayer(false);
              } else {
                entry.target.classList.add('fixed');
                heroWrapper.classList.add('fixed');
                setIsMiniPlayer(true);
              }
            });
          },
          { threshold: 0.5 }
        );
        observer.observe(videoRef);
        return () => observer.disconnect();
      }
    }
  }, [pictureInPictureOnScroll]);

  useEffect(() => {
    setIsMiniPlayerPlaying(isPlaying);
    setIsPlayingState(isPlaying);
  }, [isPlaying]);

  return (
    <div
      className={`${styles.featuredVideo__wrapper} ${
        expanded ? styles.expanded : ''
      }`}
    >
      <div className={`${styles.featuredVideo__preview}`}>
        <ReactPlayer
          className={`${styles.homepageHero__backgroundMedia}`}
          url={previewMedia?.url}
          width='100%'
          height='100%'
          playing={isPlayingPreview}
          muted={true}
          loop={true}
          playsinline
          controls={false}
          onEnded={onEnded}
        />
      </div>

      <div
        className={`${styles.featuredVideo__main} ${
          pictureInPictureOnScroll ? 'allowPIPWrapper' : ''
        }`}
      >
        <div className={'allowPIP'}>
          {isMiniPlayer && (
            <>
              <button
                type='button'
                className={`fullScreen`}
                onClick={() => {
                  setFullScreen();
                }}
              >
                Set FullScreen
              </button>
              <button
                type='button'
                className={`playButton`}
                onClick={togglePlayPause}
              >
                <Icon
                  className={styles.featuredVideoHero__playIcon}
                  id={isMiniPlayerPlaying ? 'pause' : 'play'}
                  width={isMiniPlayerPlaying ? 40 : 45}
                  height={isMiniPlayerPlaying ? 40 : 45}
                />
              </button>
            </>
          )}
          <ReactPlayer
            className={`allowPIPVideo`}
            url={featuredMedia?.url}
            width='100%'
            height='100%'
            playing={isPlayingState || isMiniPlayerPlaying}
            muted={isPlayingMain ? isMuted : true}
            loop={false}
            playsinline
            controls={!isMuted && !isMiniPlayer}
            onEnded={onEnded}
          />
          {isMiniPlayer && (
            <div className={`closeWrapper`}>
              <button className={`closeButton`} onClick={closeMiniPlayer}>
                <Icon id={'close'} width={16} height={16} />
                <span>Close</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedVideo;
